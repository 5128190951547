.WalletWrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: transparent;
    border-radius: 10px;
    margin: 16px 0 10px;
    padding: 17px 10px;
    border: 1px solid #DEDFE0;
    transition: all 0.2s ease;
    height: 80px;
}
.WalletWrapper:active {
    scale: 1.02;
}


.WalletWrapper.active {
    background-color: #8716F70D;
    border-color: #8716F7;
}

.Divider {
    width: 1px;
    height: 40px;
    background-color: #707070;
    margin: 0 16px;
}

.AddBalance {
    background-color: var(--tg-error);
    border-radius: 20px;
    padding: 9px 13px;
    cursor: pointer;
    margin-left: auto;
}

.AddBalance.zero-balance {
    background-color: var(--tg-primary-text);
}

.InfoWalletWrapper {
    display: flex;
    gap: 5px;
}

.CardWrapper, .InvoiceWrapper {
    display: grid;
    align-items: center;
    background-color: #FAFAFA;
    border-radius: 10px;
    margin: 16px 0;
    padding: 17px 20px;
    scale: 1;
    border: 1px solid #EFEFEF;
    transition: all 0.2s ease;
    grid-template-areas: "a b" "c c";
    grid-template-columns: auto auto;
}

.CardWrapper.no-bg, .InvoiceWrapper.no-bg {
    background-color: transparent;
}

.CardWrapper:first-child, .InvoiceWrapper:first-child {
    margin-top: 0;
}

.CardWrapper:not(:has( > .bottomInfo)), .InvoiceWrapper:not(:has( > .bottomInfo)) {
    height: 80px;
    grid-template-areas: "a b";
}

.CardWrapper:has( > .bottomInfo), .InvoiceWrapper:has( > .bottomInfo) {
    padding-bottom: 3px;
}

.CardWrapper:last-child, .InvoiceWrapper:last-child {
    margin-bottom: 30px;
}

.InvoiceWrapper .InvoiceList{
    display: flex;
    gap: 10px;
    align-items: center;
}

.InvoiceWrapper .InvoiceList .InvoiceListItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.CardWrapper:active {
    scale: 1.02;
}

.CardWrapper.modal, .InvoiceWrapper.modal{
    background-color: var(--tg-item-gray);
    border-color: var(--tg-gray-6);
    border-width: 0.5px;
}

.CardWrapper.active, .InvoiceWrapper.active{
    background-color: var(--tg-dust-green);
    border-color: var(--tg-primary);
    border-width: 1px;
}

.CardWrapper .CardDetail {
    display: flex;
    align-items: center;
    gap: 10px;
}


.CardWrapper .moreButton {
    text-align: right;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--tg-error);
    line-height: 0;
    top: 8px;
    right: 0;
    position: absolute;
}

.CardWrapper .empty-circle, .InvoiceWrapper .empty-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #DEDFE0;
    margin-left: auto;
}

.InvoiceSvgIconWrapper {
    height: 52px;
    width: 52px;
    border-radius: 10px;
    background-color: #fafafa;
    border: 1px solid #EFEFEF;
    display: grid;
    place-items: center;
}


.AddingInvoiceTypeWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.AddingInvoiceTypeWrapper.bordered {
    border: 1px solid #DEDFE0;
    border-radius: 10px;
    padding: 10px;
}

.AddingInvoiceTypeWrapper .remove-company {
    border:1px solid var(--tg-gray-line);
    border-radius: 20px;
    padding: 9px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    margin-left: auto;
}

.AddingInvoiceTypeWrapper .CompanyNameWrapper:has( > .skeleton-text-animated) {
    display: flex;
    flex-direction: column;
    gap: 3px;
}



.CardWrapper .bottomInfo {
    grid-area: c;
    font-size: 10px;
    display: flex;
    padding: 7px 0;
    margin-top: 14px;
    border-top: 1px solid #E9EAEA;
    gap: 3px;
}


.CardWrapper .ChangeCardButton{
    background-color: var(--tg-dark);
    border-radius: 20px;
    padding: 9px 16px;
    font-size: 15px;
    color: var(--tg-white);
    font-weight: 600;
    margin-left: auto;
}
.CheckboxWrapper{
    display: flex;
    gap: 30px;
}

.CheckboxWrapper ion-radio::part(container), .CheckboxWrapper ion-checkbox::part(container) {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #DEDFE0;
}

.CheckboxWrapper ion-radio::part(label), .CheckboxWrapper ion-checkbox::part(label) {
    margin-left: 10px;
}

.CheckboxWrapper ion-radio.radio-checked::part(container), .CheckboxWrapper ion-checkbox.checkbox-checked::part(container) {
    background: var(--tg-primary);
    border-color: transparent;
}

.CheckboxWrapper ion-radio.radio-checked::part(mark) {
    width: 5px;
    height: 10px;
    margin-bottom: 2px;

    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #fff;

    transform: rotate(45deg);
}

.BottomBtn {
    margin-top:auto;
}


.StationDetailWrapper {
    border: .5px solid #9395984D;
    border-radius: 10px;
    padding: 15px 10px;
}

.StationDetailWrapper .CustomPriceText b {
    font-size: 16px;
    color: var(--tg-primary-text);
}

.StationDetailWrapper .PriceWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.StationDetailWrapper .NavIcon {
    width: 24px;
    height: 24px;
    background-color: var(--tg-purple);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.StationDetailWrapper .AreaWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
}

.StationDetailWrapper .PhoneIconWrapper {
    width: 24px;
    height: 24px;
    background-color: var(--tg-secondary-text);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}