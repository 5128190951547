.CreditCardWrapper {
    position: relative;
    padding: 20px;
    width: fit-content;
    margin: 0 auto;
}
.CreditCardBorder{
    border: 1px solid var(--tg-gray-2);
    padding: 5px;
    border-radius: 16px;
    width: fit-content;
}
.CreditCardBorder.Default{
    border: 1px solid var(--tg-primary);
}

.CreditCardBorder svg {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    stroke: var(--tg-gray-2);
}
.CreditCardBorder.Default svg {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    stroke: var(--tg-primary);
}
.rccs__card--front, .rccs__card--back{
    background: url("../assets/debit-card-bg.svg") no-repeat center !important;
    background-size: cover !important;
}
.AddPaymentButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    gap: 8px;
}

.CreditCardFormWrapper{
    overflow-y: scroll;
    height: calc(100vh - 200px);
}

.CreditCardFormWrapper .rccs {
    margin-top: 30px;
}

.CardActionModal .Content {
    margin: 24px 0;
}

.RegisteredCardsContent .CardList {
    margin-top: 30px;
    height: calc(100vh - 170px);
    width: 100%;
    overflow-y: scroll;
}

.CardActionModal .DeleteCardAnimation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.CardActionModal .DeleteCardAnimation img.left-side {
    animation: left-side 0.5s ease-in-out forwards .1s;
    transform-origin: left bottom;
}

.CardActionModal .DeleteCardAnimation img.right-side {
    animation: right-side 0.5s ease-in-out forwards .1s;
    transform-origin: right bottom;
}

@keyframes left-side {
    0% {
        rotate: 0;
    }
    100% {
        rotate: -14deg;
    }
}

@keyframes right-side {
    0% {
        rotate: 0;
    }
    100% {
        rotate: 14deg;
    }
}

.RegisteredCardsContent .CardList .CardWrapper:active {
   scale: 1;
}

.RegisteredCardsContent .CardList .CardWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 16px;
}

.RegisteredCardsContent .MyCardSlideWrapper {
    background-color: var(--ion-color-base);
    border-radius: 12px;
    position: relative;
    height: 80px;
}

.RegisteredCardsContent .MyCardSlideWrapper:not(:last-child) {
    margin-bottom: 10px;
}

.RegisteredCardsContent .MyCardSlideWrapper .OptionsItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
}